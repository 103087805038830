import {createApp} from 'vue'
import App from './App.vue'
import './assets/styles/app.scss'
import i18n from "@/plugins/i18n";
import router from './plugins/router'
import maska from 'maska'

window.addEventListener('load', function () {
    var script = document.createElement('script');
    script.src = "//code.jivo.ru/widget/vmOQGCvTrw";
    script.async = true;
    document.body.appendChild(script);
});

const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(maska);
app.mount('#app')
