import { createRouter, createWebHistory} from 'vue-router'
import TheLanding from "@/pages/TheLanding.vue";
// import TheConfidentialityPage from "@/pages/TheConfidentialityPage.vue";
// import TheTermOfUsePage from "@/pages/TheTermOfUsePage.vue";
export default createRouter({
    history: createWebHistory(),
    routes:[{
            path: '/',
            name: 'landing-page',
            component: TheLanding
        },
        {
            path: '/contact-us',
            name: 'contact-us',
            component: () => import('../pages/components/ContactUs.vue')
        },
        {
            path: '/email-sent',
            name: 'email-sent',
            component: () => import('../pages/components/EmailSend.vue')
        },
        {
            path: '/confidentiality',
            name: 'confidentiality',
            component: () => import('../pages/TheConfidentialityPage.vue')
        },
        {
            path: '/term-of-use',
            name: 'term-of-use',
            component: () => import('../pages/TheTermOfUsePage.vue')
        }
    ]}
);


