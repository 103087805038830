<template>
  <div>
    <div class="navbar c-white d-flex justify-center text-sm">
      <div class="block-width d-flex align-center justify-between flex-wrap">
        <div class="d-flex navbar-left flex-wrap">
          <p class="mr-075">{{ $t('monday_short') }}. - {{$t('friday_short')}}.: 10:00 - 19:00</p>
          <a class="mr-075 c-white" href= "mailto:company@quant-tech.kz">company@quant-tech.kz</a>
          <a href="tel:+7(7172)-97-26-26" class="c-white">+7 7172 97 26 26</a>
        </div>
        <div class="d-flex navbar-right">
          <p class="mr-075">{{ $t('help') }}</p>
          <LanguageSelect/>
        </div>
      </div>
    </div>
    <div class="navbar-bottom d-flex justify-center">
      <div class="block-width d-flex justify-between align-center">
        <router-link to="/">
          <img
            src="@/assets/images/logos/workspace-logo.svg"
            style="height: 34px; cursor:pointer; aspect-ratio: auto 155 / 35;"
            @click="router.push('/')"
            width="155"
            height="35"
            alt="Workspace logo"
          />
        </router-link>
        <div class="d-flex"></div>
        <TheButtonPrimary content="Попробовать" :is-plain="true" @click="goTo"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import TheButtonPrimary from "@/components/TheButton/TheButtonPrimary.vue";
import {useRouter} from "vue-router";
import LanguageSelect from "@/components/LanguageSelect/LanguageSelect.vue";

const router = useRouter()
const goTo = () => {
  router.push({name: 'contact-us', params: {message: 'message_text_1'}})
}
</script>